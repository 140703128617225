import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/Layout'
import SEO from "../components/SEO"


export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={"Blog"} />
      <div className="container mx-auto w-full sm:w-2/3 lg:w-1/2 my-24 px-8 md:px-0">
          <h1 className="title-xl mb-16">{frontmatter.title}</h1>
          <div
            className="markdown-text"
            dangerouslySetInnerHTML={{ __html: html }}
          />
      </div>
    </Layout>

  )
}
export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`